@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./fonts/fonts.css");

*::-webkit-scrollbar {
  background: #ffffff;
  scrollbar-color: #000000;
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
  scrollbar-color: #000000;
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 3px solid #000000;
  width: 5px;
  height: 5px;
}

html,
body,
#root {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
}

:not(input)::selection {
  background: transparent;
}
:not(input)::-moz-selection {
  background: transparent;
}

u {
  padding: 0px 6px !important;
  text-decoration: none;
  font-family: "Trenda Semibold";
  background: black;
  color: white;
  font-size: 80%;
  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
  display: inline-block;
  animation: glitch1 4.5s infinite;
}
u:hover {
  box-shadow: none;
}

body {
  cursor: default;
  margin: 0;
  font-family: "Trenda Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: "Trenda Semibold";
}
.blink {
  animation-name: blinker;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.example-track-0 {
  background: #cccccc;
  height: 15px;
  margin-top: 5px;
  border-radius: 12px;
}
.example-track-1 {
  background: gray;
  height: 15px;
  margin-top: 5px;
  border-radius: 12px;
}
.example-thumb-0 {
  background: black;
  height: 25px;
  border-radius: 12px;
  outline: none !important;
  width: 25px;
}

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }

  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }

  10% {
    transform: none;
    opacity: 1;
  }

  27% {
    transform: none;
    opacity: 1;
  }

  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }

  35% {
    transform: none;
    opacity: 1;
  }

  52% {
    transform: none;
    opacity: 1;
  }

  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }

  50% {
    transform: none;
    opacity: 1;
  }

  72% {
    transform: none;
    opacity: 1;
  }

  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }

  80% {
    transform: none;
    opacity: 1;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes glitch2 {
  0% {
    transform: none;
    opacity: 0.45;
  }

  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }

  10% {
    transform: none;
    opacity: 0.45;
  }

  27% {
    transform: none;
    opacity: 0.45;
  }

  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }

  35% {
    transform: none;
    opacity: 0.45;
  }

  52% {
    transform: none;
    opacity: 0.45;
  }

  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }

  50% {
    transform: none;
    opacity: 0.45;
  }

  72% {
    transform: none;
    opacity: 0.45;
  }

  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }

  80% {
    transform: none;
    opacity: 0.45;
  }

  100% {
    transform: none;
    opacity: 0.45;
  }
}
