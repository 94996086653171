/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Trenda Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Regular"),
    url("Latinotype  Trenda Regular.woff") format("woff");
}

@font-face {
  font-family: "Trenda Regular It";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Regular It"),
    url("Latinotype  Trenda Regular It.woff") format("woff");
}

@font-face {
  font-family: "Trenda Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Thin"), url("Latinotype  Trenda Thin.woff") format("woff");
}

@font-face {
  font-family: "Trenda ExtraLight It";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda ExtraLight It"),
    url("Latinotype  Trenda ExtraLight It.woff") format("woff");
}

@font-face {
  font-family: "Trenda Thin It";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Thin It"),
    url("Latinotype  Trenda Thin It.woff") format("woff");
}

@font-face {
  font-family: "Trenda ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda ExtraLight"),
    url("Latinotype  Trenda ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Trenda Light";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Light"),
    url("Latinotype  Trenda Light.woff") format("woff");
}

@font-face {
  font-family: "Trenda Light It";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Light It"),
    url("Latinotype  Trenda Light It.woff") format("woff");
}

@font-face {
  font-family: "Trenda Semibold";
  font-style: normal;
  font-weight: 600;
  src: local("Trenda Semibold"),
    url("Latinotype  Trenda Semibold.woff") format("woff");
}

@font-face {
  font-family: "Trenda Semibold It";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Semibold It"),
    url("Latinotype  Trenda Semibold It.woff") format("woff");
}

@font-face {
  font-family: "Trenda Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Trenda Bold"), url("Latinotype  Trenda Bold.woff") format("woff");
}

@font-face {
  font-family: "Trenda Bold It";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Bold It"),
    url("Latinotype  Trenda Bold It.woff") format("woff");
}

@font-face {
  font-family: "Trenda Black";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Black"),
    url("Latinotype  Trenda Black.woff") format("woff");
}

@font-face {
  font-family: "Trenda Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Heavy"),
    url("Latinotype  Trenda Heavy.woff") format("woff");
}

@font-face {
  font-family: "Trenda Black It";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Black It"),
    url("Latinotype  Trenda Black It.woff") format("woff");
}

@font-face {
  font-family: "Trenda Heavy It";
  font-style: normal;
  font-weight: normal;
  src: local("Trenda Heavy It"),
    url("Latinotype  Trenda Heavy It.woff") format("woff");
}
